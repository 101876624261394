import request from '@/api/request.js'

/**
 * @Description: 发货单
 */

// 分页查询
export function pageWmsDeliveryOrder(data) {
    return request({
        url: '/api/wmsDeliveryOrder/page',
        method: 'POST',
        data
    })
}

// 搜索过滤
export function listWmsDeliveryOrder(data) {
    return request({
        url: '/api/wmsDeliveryOrder/list',
        method: 'POST',
        data
    })
}

// 查看详情
export function detailWmsDeliveryOrder(data) {
    return request({
        url: '/api/wmsDeliveryOrder/detail',
        method: 'POST',
        data
    })
}

// 添加
export function addWmsDeliveryOrder(data) {
    return request({
        url: '/api/wmsDeliveryOrder/add',
        method: 'POST',
        data
    })
}

// 编辑
export function editWmsDeliveryOrder(data) {
    return request({
        url: '/api/wmsDeliveryOrder/edit',
        method: 'POST',
        data
    })
}

// 删除
export function delWmsDeliveryOrder(data) {
    return request({
        url: '/api/wmsDeliveryOrder/delete',
        method: 'POST',
        data
    })
}

// 批量删除
export function batchDeleteWmsDeliveryOrder(data) {
    return request({
        url: '/api/wmsDeliveryOrder/batchDelete',
        method: 'POST',
        data
    })
}

